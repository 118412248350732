import React from 'react';
import '../App.css';
import { Button } from './Button';
import './Hero.css';

function Hero() {
  return (
    <div className="hero-container">
      <h1>Grant Holley</h1>
      <p>Full Stack Web Developer in Tokyo</p>


    </div>
  )
}

export default Hero;
